<template>
  <div
    class="credit-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="SYSLANG.下拉即可刷新"
      :loosing-text="SYSLANG.释放即可刷新"
      :loading-text="SYSLANG.loading"
    >
      <van-list
        v-model:loading="isloading"
        :finished="finished"
        :finished-text="SYSLANG.finished"
        :loading-text="SYSLANG.loading"
        @load="getLogs"
      >
        <ul class="logs">
          <template v-for="(log, index) in list" :key="index">
            <li>
              <div class="price">
                <span class="p">{{ log.Amount }}</span>
                <label :class="'status status_' + log.Status">{{
                  RECHERGELANG.充值状态[log.Status * 1]
                }}</label>
              </div>
              <div v-if="log.Status == 2" class="reason">
                {{ RECHERGELANG.驳回原因 }}{{ log.Reason }}
              </div>
              <div class="time">
                {{ PAGELANG.创建时间 }}
                <span>{{ log.AddDTimeFormat }}</span>
              </div>
            </li>
          </template>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>

  <div
    class="pc-my-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <MyMenu :agentInfo="agentInfo" pagename="credit" />
    <div class="main">
      <div class="big-title">{{ RECHERGELANG.充值记录 }}</div>
      <div class="control">
        <el-date-picker
          v-model="timer"
          type="daterange"
          range-separator="-"
          :start-placeholder="RECHERGELANG.起始时间"
          :end-placeholder="RECHERGELANG.结束时间"
          value-format="YYYY-MM-DD"
          size="large"
          style="margin-right: 20px"
        />

        <el-button
          color="#000"
          size="large"
          @click="onLayar"
          style="margin-right: 40%"
          >{{ RECHERGELANG.筛选 }}</el-button
        >
      </div>

      <el-table
        :data="list"
        class="logs"
        size="large"
        :empty-text="SYSLANG.finished"
      >
        <el-table-column prop="Amount" :label="RECHERGELANG.金额">
          <template #default="scope">
            <span class="price">{{ scope.row.Amount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Status" :label="RECHERGELANG.类型" width="140">
          <template #default="scope">
            <span :class="'status status_' + scope.row.Status">{{
              RECHERGELANG.充值状态[scope.row.Status * 1]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="Reason"
          :label="RECHERGELANG.驳回原因2"
          width="220"
        >
          <template #default="scope">
            <span v-if="scope.row.Reason" style="color: #f14b6d">{{
              scope.row.Reason
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="AddDTimeFormat"
          :label="RECHERGELANG.创建时间"
          width="180"
          align="right"
        />
      </el-table>

      <el-pagination
        v-if="pagecount > 1"
        @current-change="changePage"
        layout="prev, pager, next"
        :page-size="pagesize"
        :pager-count="pagecount"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie } from "../util";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      list: [],
      refreshing: false,
      finished: false,
      isloading: false,
      curpage: 0,
      pagecount: 0,
      starttime: "",
      endtime: "",
      init: true,
      agentInfo: {},
      total: 0,
      pagesize: 0,
      timer: [],
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.income;
    this.RECHERGELANG = this.LANG.pages.recharge;

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.init = true;
      this.axios
        .get(this.actions.credit)
        .then(({ data }) => {
          closeToast();
          let { code, msg, agentInfo } = data;
          console.log(code, msg);
          if (code == "SUCCESS") {
            this.agentInfo = agentInfo;

            this.init = false;
            this.getLogs();
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.CreditRechargeLogs.getMemberInfo.error", error);
          this.getMemberInfo();
        });
    },
    getLogs() {
      if (this.init) {
        return false;
      }
      if (this.refreshing) {
        this.refreshing = false;
      }
      this.finished = true;

      this.curpage++;
      this.axios
        .post(this.actions.credit_recharge_logs, {
          curpage: this.curpage,
          starttime: this.StartTime,
          endtime: this.EndTime,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, logs } = data;
          let { curpage, pagecount, list, total, pagesize } = logs;
          console.log("list", list);

          if (code == "SUCCESS") {
            this.curpage = curpage * 1;
            this.pagecount = pagecount * 1;
            this.total = total * 1;
            this.pagesize = pagesize * 1;
            this.list = this.list.concat(list);
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.isloading = false;
          this.finished = false;

          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.CreditRechargeLogs.getLogs.error", error);
          this.curpage--;
          this.getLogs();
        });
    },
    // 下拉刷新数据
    onRefresh() {
      this.list = [];
      this.isloading = false;
      this.finished = false;
      this.curpage = 0;
      this.pagecount = 0;
      this.pagesize = 0;
      this.total = 0;

      this.getLogs();
    },

    onLayar() {
      if (this.timer) {
        this.StartTime = this.timer[0];
        this.EndTime = this.timer[1];
      } else {
        this.StartTime = "";
        this.EndTime = "";
      }
      this.onRefresh();
    },

    changePage(val) {
      this.curpage = val - 1;
      this.list = [];
      this.getLogs();
    },
  },
};
</script>

<style src="../assets/css/creditlogs.css" scoped></style>
<style scoped>
.credit-page .price .p::before {
  content: var(--aog-currency);
  font-size: 70%;
}

.status {
  font-size: 14px;
  font-weight: normal;
}
.status.status_0 {
  color: #fe295f;
}
.status.status_1 {
  color: green;
}
.status.status_2 {
  color: #f5a624;
}
</style>